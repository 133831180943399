import React from 'react'
import './portfolio.css'
import NETFLIXLOGO from '../assets/NETFLIXLOGO.jpg'
import ICL from '../assets/ICL.png'
import HMC from '../assets/HMC.jpg'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={NETFLIXLOGO} alt=''></img>
          </div>
            <h3>Netflix Clone</h3>
            <div className="portfolio__item-cta">
              <a href='https://github.com/hamzadahir' className='btn' target='_blank' rel="noreferrer">Github</a>            
              <a href='https://netflix-clone-1096d.firebaseapp.com/' className='btn btn-primary' target='_blank' rel="noreferrer">Visit Demo</a>   
            </div>         
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={ICL} alt=''></img>
          </div>
            <h3>Lansing Islamic Center</h3>
            <div className='portfolio__item-cta'>
              <a href='https://github.com/hamzadahir' className='btn' target='_blank' rel="noreferrer">Github</a>            
              <a href='https://islamiccenterlansing.org/' className='btn btn-primary' target='_blank' rel="noreferrer">Visit Site</a>       
            </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={HMC} alt=''></img>
          </div>
            <h3>Halas Ministries & Counseling</h3>
            <div className='portfolio__item-cta'>
              <a href='https://github.com/hamzadahir' className='btn' target='_blank' rel="noreferrer">Github</a>            
              <a href='http://www.halasministries.net/' className='btn btn-primary' target='_blank'rel="noreferrer">Visit Site</a>       
            </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio