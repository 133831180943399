import React from 'react'
import './about.css'
import ABOUTME from '../assets/ABOUTME.JPG'
// import ME from '../assets/ME.png'
// import {FaAward} from  'react-icons/fa'
// import {FiUsers} from 'react-icons/fi'
// import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
            <div className="about__me-image">
              <img src={ABOUTME} alt='About '/>
            </div>
        </div>
        <div className="about__content">
          {/* <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>2 Years </small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>200+ Worldwide </small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
          </div> */}

          <p>
            Full stack developer with a proven ability to deliver robust, effective products in collaborative settings under strict and often tight time constraints. Always seeking personal growth, I expanded my coding acumen by successful completion of a full time 6 month coding bootcamp focused on full stack development with HTML, CSS, Javascript, Node, Express, and MongoDB.  Upon completion, I was invited to return as a teaching assistant. As a skilled collaborator, I lead a team of 3 developers in the creation of a website to notify congregants of events and services over a period of 2 months.  Our team maximized search engine optimization and eventually increased traffic to the site by 73%. I am now eager to tackle more complex challenges and continue to find ways to build backend Express & Node JS solutions for real-world problems.
          </p>

          <a href='#contact' className='btn btn-primary'>Contact Me</a>
        </div>
      </div>
    </section>
  )
}

export default About