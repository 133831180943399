import React from 'react'
import './testimonials.css'
import ICL from '../assets/ICL.png'
import HMC from '../assets/HMC.jpg'
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: ICL,
    name: 'Lansing Islamic Center President', 
    review: 'Hamza is an amazing developer. He was dedicted to getting the job done and helping us launch our new website in a timely manner. He is well versed in full stack development! Please feel free to contact me with any questions.'
  },
  {
    avatar: HMC,
    name: 'Halas Ministries & Counseling CEO', 
    review: 'I have had the pleasure of working with Hamza over the past several months. During this time I have personally noticed the hard work, effort, amd consistency that Hamza puts into his work. He is a smart individual who is good at what he does and is a team player who cares about others. If given the opportunity I would hire him for any of my coding needs. I believe that he will be an exceptional addition to any prospecting company in the near future. Please feel free to contact me with any questions.'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {
          data.map (({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} alt='avatar'/>
                </div>
                <h5 className="client__name">{name}</h5>
                <small className="client__review">{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials